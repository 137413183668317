<template>
  <div class="project-mortgage-approve-la">
    <fd-form class="card" @submit.prevent="submitForm">
      <h2 class="px-2 py-4">Approve Loan Application</h2>

      <modal-body class="p-2">
        <fd-textarea
          v-model="form.description"
          class="col-12 px-1 mb-2"
          label="Remark"
          name="approveLARemark"
          type="text"
          :validators="[validator.required]"
        >
        </fd-textarea>
        <fd-input
          v-model="form.finalLoanAmount"
          class="col-12 px-1 mb-2"
          label="Final Loan Amount"
          name="approveLAFinalLoanAmount"
          type="text"
          :validators="[validator.required, validator.price]"
        >
        </fd-input>
      </modal-body>
      <modal-footer class="row justify-content-end p-2">
        <button type="button" class="btn" @click="$emit('cancel')">
          Cancel
        </button>
        <button class="btn main ml-1">Approve</button>
      </modal-footer>
    </fd-form>
  </div>
</template>

<script>
import {
  required,
  price
} from "@/components/GlobalComponents/FormComponents/Validator/rules";
export default {
  components: {},
  mixins: [],
  props: {},
  data: function () {
    return {
      form: {
        description: "",
        finalLoanAmount: ""
      },
      validator: {
        required: required,
        price: price
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    submitForm() {
      this.$emit("submit", this.form);
    }
  }
};
</script>

<style lang="scss">
.project-mortgage-approve-la {
  min-width: 450px;
}
</style>

